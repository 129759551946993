import { useUser } from '@clerk/nextjs';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useIntercom } from 'react-use-intercom';
import { useUserContext } from '~/react_context/UserContext';

export function Intercom() {
	const { user } = useUser();
	const { businessName, businessId, impersonatingUser } = useUserContext();

	const { boot } = useIntercom();

	useEffect(() => {
		if (impersonatingUser !== null) {
			toast.success(`Impersonating ${impersonatingUser} - Intercom disabled`, {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
		} else {
			boot({
				name: user?.fullName ?? undefined,
				email: user?.primaryEmailAddress?.emailAddress,
				userId: user?.id,
				phone: user?.primaryPhoneNumber?.phoneNumber,
				company: {
					name: businessName ?? undefined,
					companyId: String(businessId) ?? undefined,
				},
			});
		}
	}, [user?.id, impersonatingUser]);

	return null;
}
