import { KnockFeedProvider, KnockProvider, useKnockFeed } from '@knocklabs/react';
import { isNotNullish, isNullish } from '@utils/types';
import DOMPurify from 'dompurify';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useUserContext } from '~/react_context/UserContext';

const NotificationsToast = () => {
	const router = useRouter();
	const { feedClient } = useKnockFeed();

	const onNotificationsReceived = useCallback(
		async ({ items }: { items }) => {
			// Whenever we receive a new notification from our real-time stream, show a toast
			// (note here that we can receive > 1 items in a batch)
			const notifications = items.filter((notification) => notification.data.showToast !== false && isNullish(notification.seen_at));

			if (notifications.length >= 3) {
				toast.info(`You have ${notifications.length} new notifications`, {
					position: 'top-right',
					autoClose: 8000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
					onClick: () => router.push('/inbox'),
				});
			} else {
				notifications.forEach((notification) => {
					const url = notification.data.url ?? '/inbox';

					const sanitizedHtmlContent = DOMPurify.sanitize(notification.blocks[0].rendered);

					toast.info(<div dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }} />, {
						position: 'top-right',
						autoClose: 8000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'light',
						onClick: () => router.push(url),
					});
				});
			}

			await feedClient.markAsSeen(items);
		},
		[feedClient, toast]
	);

	useEffect(() => {
		// Receive all real-time notifications on our feed
		feedClient.on('items.received.realtime', onNotificationsReceived);

		// Cleanup
		return () => feedClient.off('items.received.realtime', onNotificationsReceived);
	}, [feedClient, onNotificationsReceived]);

	return null;
};

export const KnockNotifications = () => {
	const { id } = useUserContext();

	return (
		<KnockProvider apiKey={process.env.NEXT_PUBLIC_KNOCK_PUBLIC_API_KEY!} userId={`${id}`}>
			<KnockFeedProvider feedId={process.env.NEXT_PUBLIC_KNOCK_FEED_CHANNEL_ID!}>
				<NotificationsToast />
			</KnockFeedProvider>
		</KnockProvider>
	);
};
