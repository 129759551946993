import { EndSystem, Role } from '@prisma/client';
import { createContext, ReactNode, useContext } from 'react';
import { trpc } from '~/utils/trpc';
import { useAuth } from '@clerk/nextjs';
import { JobSummary } from '@services/context.service';
import { FeatureFlag } from '@utils/featureFlags';
import { ErrorScreen } from '~/components/error-handling/ErrorScreen';
import { LoadingScreen } from '~/components/LoadingScreen';
import { PermissionKey } from '@schema/permission.schema';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { useSessionStorage } from '@mantine/hooks';
import { datadogLogs } from '@datadog/browser-logs';

export interface IUserState {
	allowedPermissions: PermissionKey[];
	autoSyncQuickbooks: boolean;
	businessCategorySummary: JobSummary;
	businessId: number | null;
	businessName: string | null;
	businessTimezone: string;
	defaultGlassCostCodeId: number | null;
	defaultLaborCostCodeId: number | null;
	defaultMaterialCostCodeId: number | null;
	email: string | null;
	emailSignature: string | null;
	featureFlags: FeatureFlag[];
	firstName: string | null;
	hiddenEstimateColumns: string[] | null;
	id: number | null;
	impersonatingUser: string | null;
	isQuoteLineItemToggleEnabled: boolean;
	isShownOnSchedule: boolean;
	lastName: string | null;
	phone: string | null;
	quickbooksEnabled: boolean | null;
	quickbooksEndSystem: EndSystem | null;
	quickEstimatesCount: number;
	role: Role | null;
	shouldBccOnSentEmails: boolean;
	shouldSendEmailNotifications: boolean;
	shouldSendSmsNotifications: boolean;
	shouldReceiveWebNotifications: boolean;
	shouldReceiveMentionNotifications: boolean;
	shouldReceiveNoteNotifications: boolean;
	shouldReceivePaymentNotifications: boolean;
	shouldReceiveTextMessageNotifications: boolean;
	shouldReceiveKeyDateNotifications: boolean;
	shouldReceiveTaskNotifications: boolean;
	shouldReceiveJobTaskNotifications: boolean;
	shouldReceiveDailyReportNotifications: boolean;
	tasksAssignedByMeCount?: number;
	tasksAssignedToMeCount?: number;
	taskCount: number;
	taskCountWithEmailDeliveryFailed?: number;
	tilledEnabled: boolean;
	unreadNotificationsCount?: number;
}

// TODO: Want to add more user info here but not working for some reason, for now using useUser()
export const UserContext = createContext<IUserState | null>(null);

export const useUserContext = () => {
	const userContext = useContext(UserContext);

	if (!userContext) {
		throw new Error('User Context Data Error');
	}

	return userContext;
};

type Props = {
	children: ReactNode;
};

export const UserProvider = (props: Props) => {
	const { actor, isLoaded, userId } = useAuth();
	const [hasSeenTimezoneWarning, setHasSeenTimezoneWarning] = useSessionStorage({
		key: 'hasSeenTimezoneWarning',
		defaultValue: false,
	});
	const userContextQuery = trpc.context.forUser.useQuery({ clerkUserId: userId! }, { enabled: !!userId, refetchOnWindowFocus: false });

	if (isLoaded && userId) {
		if (userContextQuery.isSuccess) {
			const { data } = userContextQuery;

			if (data.role) {
				const userContextValues = {
					allowedPermissions: data.allowedPermissions,
					autoSyncQuickbooks: data.autoSyncQuickbooks,
					businessCategorySummary: data.businessCategorySummaries,
					businessId: data.businessId,
					businessName: data.businessName,
					businessTimezone: data.businessTimezone,
					defaultGlassCostCodeId: data.defaultGlassCostCodeId,
					defaultLaborCostCodeId: data.defaultLaborCostCodeId,
					defaultMaterialCostCodeId: data.defaultMaterialCostCodeId,
					email: data.email,
					emailSignature: data.emailSignature,
					featureFlags: data.featureFlags,
					firstName: data.firstName,
					hiddenEstimateColumns: data.hiddenEstimateColumns,
					id: data.id,
					impersonatingUser: actor?.sub ?? null,
					isQuoteLineItemToggleEnabled: data.isQuoteLineItemToggleEnabled,
					isShownOnSchedule: data.isShownOnSchedule,
					lastName: data.lastName,
					phone: data.phone,
					quickbooksEnabled: data.hasQuickbooks,
					quickbooksEndSystem: data.quickbooksEndSystem,
					quickEstimatesCount: data.quickEstimatesCount,
					role: data.role,
					shouldBccOnSentEmails: data.shouldBccOnSentEmails,
					shouldSendEmailNotifications: data.shouldSendEmailNotifications,
					shouldReceiveDailyReportNotifications: data.shouldReceiveDailyReportNotifications,
					shouldReceiveKeyDateNotifications: data.shouldReceiveKeyDateNotifications,
					shouldReceiveTaskNotifications: data.shouldReceiveTaskNotifications,
					shouldReceiveJobTaskNotifications: data.shouldReceiveJobTaskNotifications,
					shouldSendSmsNotifications: data.shouldSendSmsNotifications,
					shouldReceiveWebNotifications: data.shouldReceiveWebNotifications,
					shouldReceiveMentionNotifications: data.shouldReceiveMentionNotifications,
					shouldReceiveNoteNotifications: data.shouldReceiveNoteNotifications,
					shouldReceivePaymentNotifications: data.shouldReceivePaymentNotifications,
					shouldReceiveTextMessageNotifications: data.shouldReceiveTextMessageNotifications,
					tasksAssignedByMeCount: data.tasksAssignedByMeCount,
					tasksAssignedToMeCount: data.tasksAssignedToMeCount,
					taskCount: data.taskCount,
					taskCountWithEmailDeliveryFailed: data.taskCountWithEmailDeliveryFailed,
					tilledEnabled: data.tilledEnabled,
					unreadNotificationsCount: data.unreadNotificationsCount,
				} as IUserState;

				if (!hasSeenTimezoneWarning) {
					const localTimezone = DateTime.local().zoneName;
					if (localTimezone !== userContextValues.businessTimezone) {
						toast.warn(
							<>
								<p>Your device's timezone is {localTimezone}.</p>
								<p>Dates and times will be displayed in your business timezone ({userContextValues.businessTimezone}).</p>
							</>,
							{
								autoClose: false,
								closeOnClick: false,
								draggable: false,
								hideProgressBar: true,
								position: 'top-center',
								toastId: 'timezone warning',
							}
						);

						datadogLogs.logger.warn('Device/business timezone mismatch', {
							localTimezone,
							businessTimezone: userContextValues.businessTimezone,
						});

						setHasSeenTimezoneWarning(true);
					}
				}

				return <UserContext.Provider value={userContextValues}>{props.children}</UserContext.Provider>;
			} else {
				return <ErrorScreen message={'No role found for user.'} />;
			}
		} else if (userContextQuery.isLoading) {
			return <LoadingScreen srText="Loading" />;
		} else if (userContextQuery.isError) {
			return (
				<ErrorScreen
					status={userContextQuery.error?.data?.code === 'NOT_IMPLEMENTED' ? 'METHOD_NOT_SUPPORTED' : userContextQuery.error?.data?.code}
				/>
			);
		} else {
			return <ErrorScreen message={'Error loading user.'} />;
		}
	} else if (userId === null) {
		return <ErrorScreen message={'No auth ID found.'} />;
	} else {
		return <LoadingScreen srText="Loading" />;
	}
};
