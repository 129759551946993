import { ClerkProvider, RedirectToSignIn, SignedIn, SignedOut } from '@clerk/nextjs';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { WithFeatureFlags } from '@utils/featureFlags';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import RUMUser from '~/components/analytics/RUMUser';
import { Intercom } from '~/components/intercom/Intercom';
import { SideNavContainer } from '~/components/layout/';
import { KnockNotifications } from '~/components/layout/KnockNotifications';
import { classNames } from '~/components/utils';
import { FeatureFlagProvider } from '~/react_context/FeatureFlagContext';
import { UserProvider } from '~/react_context/UserContext';

import '@knocklabs/react/dist/index.css';

type DefaultLayoutProps = {
	children: ReactNode;
	pageTitle?: string;
	backgroundColor?: string;
} & WithFeatureFlags;

export const DefaultLayout = ({ children, pageTitle, featureFlags, backgroundColor = 'bg-white' }: DefaultLayoutProps) => {
	const { asPath: pathname } = useRouter();

	return (
		<>
			<Head>
				<title>{pageTitle ?? 'Glazier'}</title>
				<link rel="icon" href="/favicon.ico" />
				{/* Responsive Meta Tag for proper scaling */}
				<meta name="viewport" content="initial-scale=1, width=device-width" />
			</Head>
			<ClerkProvider>
				<SignedIn>
					<UserProvider>
						<FeatureFlagProvider featureFlags={featureFlags}>
							<IntercomProvider appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID!}>
								{<RUMUser />}
								{<Intercom />}
								<main className={classNames('h-screen', backgroundColor)}>
									<div className="h-full overflow-auto">
										{<KnockNotifications />}
										<SideNavContainer pathname={pathname}>{children}</SideNavContainer>
									</div>
								</main>
							</IntercomProvider>
						</FeatureFlagProvider>
					</UserProvider>
				</SignedIn>
				<SignedOut>
					<RedirectToSignIn />
				</SignedOut>
			</ClerkProvider>

			{process.env.NODE_ENV !== 'production' && <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />}
		</>
	);
};
